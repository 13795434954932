import { useState } from 'react';
import { sleep } from '@/helpers/utils';

type showMessageAsStreamProps = {
  messageToStream: string; 
  scrollToBottom?: () => void; 
  delay?: number;
  stream?: boolean
}

export const useStreamingMessage = () => {
  const [streamingActive, setStreamingActive] = useState<boolean>(false);
  const [messageStreaming, setMessageStreaming] = useState<string>('');

  const showMessageAsStream = async ({messageToStream, scrollToBottom = () => {}, delay = 6, stream = true}: showMessageAsStreamProps) => {
    setStreamingActive(true);
    
    if (stream) {
      setMessageStreaming('');
      const text: string = messageToStream.trim();
      const length: number = text.length;
      const chunkSize: number = 30;
      let textToShow: string = '';

      for (let i = 0; i < length; i++) {
        textToShow = textToShow + text[i];
        setMessageStreaming(textToShow);
        await sleep(delay);

        if (i % chunkSize === 0) {
          scrollToBottom();
        }
      }
    }else{
      setMessageStreaming(messageToStream);
    }

    setStreamingActive(false);
    scrollToBottom();
  };

  return { streamingActive, showMessageAsStream, messageStreaming };
};